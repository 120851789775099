import React, { useEffect } from 'react';

import { Table, Button } from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';

import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';

import MainLayout from '../../../component/MainLayout';
import { SkillAddModal, SkillEditModal } from './SkillModal';
import { showAddModalOpen, showEditModalOpen } from '../../../redux/slice/healthSlice';
import { useDelSkillMutation, useGetSkillQuery } from '../../../redux/Api/adminApi';

const Skill = () => {
  const { data, isLoading } = useGetSkillQuery();
  const [delSkill, response] = useDelSkillMutation();
  const dispatch = useDispatch();

  const columns = [
    {
      key: '1',
      title: 'Id',
      dataIndex: '_id',
    },
    {
      key: '2',
      title: 'Name',
      dataIndex: 'name',
    },
    // {
    //   key: '3',
    //   title: 'Status',
    //   dataIndex: 'status',
    // },
    // {
    //   key: '4',
    //   title: 'Type',
    //   dataIndex: 'type',
    // },
    {
      key: '5',
      title: 'Action',
      render: (record) => {
        return (
          <>
            <EditOutlined
              onClick={() => dispatch(showEditModalOpen(record))}
              style={{ color: 'green', marginLeft: '20px' }}
            />
            <DeleteOutlined onClick={() => delSkill(record._id)} style={{ color: 'red', marginLeft: '20px' }} />
          </>
        );
      },
    },
  ];

  useEffect(() => {
    if (response && response.data) {
      toast.success(response.data.message);
    }
  }, [response]);

  return (
    <>
      <MainLayout>
        <div className='table'>
          <Button className='gred-button' onClick={() => dispatch(showAddModalOpen())}>
            Add
          </Button>
          <Table
            dataSource={data && data.list}
            columns={columns}
            scroll={{ y: 400, x: 1000 }}
            bordered
            loading={isLoading}
          />
        </div>
        <SkillEditModal />
        <SkillAddModal />
      </MainLayout>
    </>
  );
};

export default Skill;
