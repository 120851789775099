import { Input, Modal } from 'antd';
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { showAddModalClose } from '../../../redux/slice/healthSlice';
import { showEditModalClose } from '../../../redux/slice/healthSlice';
import {
  useCreateChronicDiseaseMutation,
  useEditChronicDiseaseMutation,
  usePostSkillMutation,
  usePutSkillMutation,
} from '../../../redux/Api/adminApi';
import { toast } from 'react-toastify';

export const ChronicAddModal = () => {
  const [skillAdd, setSkillAdd] = useState();

  const { isModalAdd } = useSelector((state) => state.health);
  const dispatch = useDispatch();

  const [postHealth, response] = useCreateChronicDiseaseMutation();

  const handleOk = () => {
    console.log(skillAdd);

    if (!!skillAdd?.hasOwnProperty('name') && !!skillAdd?.hasOwnProperty('cost')) {
      postHealth(skillAdd);
      dispatch(showAddModalClose());
    } else {
      return toast.error('Please fill the data');
    }
  };

  const handelChange = (e) => {
    let { name, value } = e.target;
    setSkillAdd({ ...skillAdd, [name]: value });
  };
  useEffect(() => {
    if (response && response.data) {
      toast.success(response.data.message);
    }
  }, [response]);

  return (
    <div>
      <Modal
        title='Add'
        open={isModalAdd}
        onOk={handleOk}
        onCancel={() => dispatch(showAddModalClose())}
        okButtonProps={{ style: { background: 'var(--gradient)' } }}
        okText='Add'
      >
        <div className='modal-input'>
          <Input placeholder='Enter Name' onChange={(e) => handelChange(e)} name='name' />
        </div>
        <div className='modal-input'>
          <Input placeholder='Enter Cost' onChange={(e) => handelChange(e)} name='cost' />
        </div>
      </Modal>
    </div>
  );
};

export const ChronicEditModal = () => {
  const { isModalEdit, editRecord } = useSelector((state) => state.health);
  const [skillEdit, setSkillEdit] = useState();
  const dispatch = useDispatch();
  const [putSkill, response] = useEditChronicDiseaseMutation();

  useEffect(() => {
    if (editRecord) {
      setSkillEdit({ name: editRecord.name, cost: editRecord.cost });
    }
  }, [editRecord]);

  const handleOk = () => {
    let data = {
      id: editRecord._id,
      type: skillEdit,
    };
    putSkill(data);
    dispatch(showEditModalClose());
  };
  const handelChange = (e) => {
    let { name, value } = e.target;
    setSkillEdit({ ...skillEdit, [name]: value });
  };

  useEffect(() => {
    if (response && response.data) {
      toast.success(response.data.message);
    }
  }, [response]);

  return (
    <div>
      <Modal
        title='Edit'
        open={isModalEdit}
        onOk={handleOk}
        onCancel={() => dispatch(showEditModalClose())}
        okButtonProps={{ style: { background: 'var(--gradient)' } }}
        okText='Edit'
      >
        <div className='modal-input'>
          <Input placeholder='Enter Name' onChange={(e) => handelChange(e)} name='name' value={skillEdit?.name || ''} />
        </div>
        <div className='modal-input'>
          <Input placeholder='Enter Cost' onChange={(e) => handelChange(e)} name='cost' value={skillEdit?.cost || ''} />
        </div>
      </Modal>
    </div>
  );
};
